import TransactionDetails from "../../components/admin/transaction/TransactionDetails";

const TransactionDetailsPage = () => {
  return (
    <>
      <TransactionDetails />
    </>
  );
};

export default TransactionDetailsPage;
