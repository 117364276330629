import BlogForm from "../../components/admin/blog/BlogForm"

function BlogFromPage() {
  return (
    <>
      <BlogForm />
    </>
  )
}

export default BlogFromPage;