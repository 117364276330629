import React from 'react'
import ForgotPassword from '../../components/website/forgot-password/ForgotPassword'

const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPassword />
    </>
  )
}

export default ForgotPasswordPage