import BlogBanner from '../../components/website/blog/BlogBanner'
import BlogList from '../../components/website/blog/BlogList'

const BlogPage = () => {
  return (
    <>
      <BlogBanner />
      <BlogList />
    </>
  )
}

export default BlogPage