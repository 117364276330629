
import BlogDetail from '../../components/website/blog/BlogDetail'

const BlogDetailsPage = () => {
  return (
    <>
      <BlogDetail />
    </>
  )
}

export default BlogDetailsPage