import React from "react";
import ForgotPassword from "../../components/admin/forgot-password/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPassword />
    </>
  );
};

export default ForgotPasswordPage;
