import React from "react";
import BidDocuments from "../../../components/portal/bids/BidDocuments";

const BidDocumentsPage = () => {
  return (
    <>
      <BidDocuments />
    </>
  );
};

export default BidDocumentsPage;
