import React from 'react'
import ResetPassword from '../../components/website/reset-password/ResetPassword'


const ResetPasswordPage = () => {
  return (
    <>
      <ResetPassword />
    </>
  )
}

export default ResetPasswordPage