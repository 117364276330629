import EmailVerification from "../../components/website/email-verification/EmailVerification";

const EmailVerificationPage = () => {
  return (
    <>
      <EmailVerification />
    </>
  );
};

export default EmailVerificationPage;
