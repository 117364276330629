import React from 'react'
import RegistrationBanner from "../../components/website/registration/RegistrationBanner"
import RegistrationForm from '../../components/website/registration/RegistrationForm'
const RegistrationPage = () => {
  return (
    <>
      <RegistrationBanner />
      <RegistrationForm />
    </>
  )
}

export default RegistrationPage