import React from "react";
import BidQuestions from "../../../components/portal/bids/BidQuestions";

const BidQuestionsPage = () => {
  return (
    <>
      <BidQuestions />
    </>
  );
};

export default BidQuestionsPage;
