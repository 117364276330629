import React from "react";
import CompanyList from "../../components/admin/companies/CompanyList";

const CompanyListPage = () => {
  return (
    <>
      <CompanyList />
    </>
  );
};

export default CompanyListPage;
