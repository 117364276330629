import React from "react";
import TransactionPayments from "../../components/admin/transaction/TransactionPayments";

const TransactionPaymentPage = () => {
  return (
    <>
      <TransactionPayments />
    </>
  );
};

export default TransactionPaymentPage;
