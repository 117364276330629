import React from 'react'
import LoginBanner from "../../components/website/login/LoginBanner"
import LoginForm from '../../components/website/login/LoginForm'

const LoginPage = () => {
  return (
    <>
      <LoginBanner />
      <LoginForm />
    </>
  )
}

export default LoginPage