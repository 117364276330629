import React from "react";
import QueryDetail from "../../components/admin/query/QueryDetail";

const QueryDetailPage = () => {
  return (
    <>
      <QueryDetail />
    </>
  );
};

export default QueryDetailPage;
