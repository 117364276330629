import React from 'react'
import SetNewPassword from '../../components/portal/settings/SetNewPassword'

const SettingPage = () => {
  return (
    <>
      <SetNewPassword />
    </>
  )
}

export default SettingPage