import React from "react";
import CategoryForm from "../../../components/portal/company-profile/CategoryForm";

const CompanyCategoryPage = () => {
  return (
    <>
      <CategoryForm />
    </>
  );
};

export default CompanyCategoryPage;
