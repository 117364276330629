import BidForm from "../../../components/portal/bids/BidForm"

function BidFormPage() {
  return (
    <>
      <BidForm />
    </>
  )
}

export default BidFormPage