export const AdminApiUrls = Object.freeze({
  ADMIN_LOGIN: "/user/admin/login/",
  LIST_COMPANIES: "/company/admin/",
  GET_COMPANY: "/company/admin/",
  UPDATE_BID_PRICE: "/payment/admin/amount/",
  GET_PAYMENT_AMOUNT: "/payment/amount/",
  GET_TRANSACTIONS: "/payment/admin/transaction/",
  TRANSACTION_DETAILS: "/payment/admin/transaction/",
  GET_PAYMENTS: "/payment/admin/order/",
});
