import React from 'react'
import UserProfile from '../../components/portal/user-profile/UserProfile'

const UserProfilePage = () => {
  return (
    <>
      <UserProfile />
    </>
  )
}

export default UserProfilePage