import React from "react";
import QueryList from "../../components/admin/query/QueryList";

const QueryListPage = () => {
  return (
    <>
      <QueryList />
    </>
  );
};

export default QueryListPage;
