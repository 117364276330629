import React from "react";
import BidCategories from "../../../components/portal/bids/BidCategories";

const BidCategoriesPage = () => {
  return (
    <>
      <BidCategories />
    </>
  );
};

export default BidCategoriesPage;
