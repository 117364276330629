import CompanyProfile from "../../../components/portal/company-profile/CompanyProfile"


const CompanyProfilePage = () => {
  return (
    <>
      <CompanyProfile />
    </>
  )
}

export default CompanyProfilePage