export const WebsiteApiUrls = Object.freeze({
  SEND_OTP: "/user/customer/register/send_otp/",
  REGISTER: "/user/customer/register/",
  VERIFY_EMAIL: "/user/customer/verify_email/",
  RESEND_VERIFY_EMAIL: "/user/customer/register/resend_email/",
  LOGIN_EMAIL: "/user/customer/login/email/",
  LOGIN_MOBILE: "/user/customer/login/mobile/",
  FORGOT_PASSWORD_EMAIL: "/user/customer/forgot_password/email/",
  FORGOT_SEND_OTP: "/user/customer/forgot_password/send_otp/",
  FORGOT_VERIFY_OTP: "/user/customer/forgot_password/verify_otp/",
  RESET_PASSWORD: "/user/customer/reset_password/",
});
