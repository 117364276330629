import React from "react";
import Dashboard from "../../components/portal/dashboard/Dashboard";

const DashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardPage;
